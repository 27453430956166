import React, { FC } from "react";
import { fetchApplicationById } from "store/domain-data/application/application";
import { fetchApplicationDocumentContainers } from "store/domain-data/document-containers/documentContainers";
import { fetchApplicationDocuments } from "store/domain-data/application-document-relation/applicationDocumentRelation";
import { useStoreDispatch, useStoreSelector } from "store/hooks";
import { selectDocumentEntitiesByApplicationId } from "store/domain-data/document/document";
import { DocumentUtil } from "models/Document.model";
import { usePollApplicationDocuments } from "hooks/usePollApplicationDocuments";
import { selectApplicationDocumentUploadQueuedItems } from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";

export type Children = {
  applicationId: number;
  children?: React.ReactNode;
};

const DocumentDataProvider: FC<Children> = ({ applicationId, children }) => {
  const dispatch = useStoreDispatch();
  React.useEffect(() => {
    dispatch(fetchApplicationById(applicationId));
    dispatch(fetchApplicationDocumentContainers(applicationId));
    dispatch(fetchApplicationDocuments(applicationId));
  }, [dispatch, applicationId]);

  const documents = useStoreSelector((state) => selectDocumentEntitiesByApplicationId(state, applicationId));
  const queuedItems = useStoreSelector(selectApplicationDocumentUploadQueuedItems);
  const hasDocuments = documents.length > 0;
  const hasUnsettledDocuments = documents.some((document) => DocumentUtil.isUnsettled(document, queuedItems));

  usePollApplicationDocuments(applicationId, hasDocuments && hasUnsettledDocuments);

  return <React.Fragment>{children}</React.Fragment>;
};

export default DocumentDataProvider;
