import React from "react";
import { getFileNameComponents } from "utils/getFileNameComponents";
import styled, { css } from "styled-components/macro";
import prettyBytes from "pretty-bytes";
import { useTranslation } from "react-i18next";
import { DocumentStatus } from "models/DocumentStatus.model";
import { DocumentErrorCodeUtil } from "models/DocumentErrorCode.model";
import Guard from "components/Guard/Guard";
import FontIcon from "components/FontIcon/FontIcon";
import { ODL_ICONS } from "components/FontIcon/FontIcon.constant";
import { formatDateStr } from "utils/formatDateStr";
import DocumentIcon from "components/DocumentIcon/DocumentIcon";
import { DocumentErrorCode } from "models/DocumentErrorCode.model";
import FlexBox from "components/FlexBox/FlexBox";
import HoverTooltip from "components/HoverTooltip/HoverTooltip";
import Spacer from "components/Spacer/Spacer";
import { FILE_TYPES } from "constants/configs";
import { useStoreSelector } from "store/hooks";
import { selectApplicationDocumentUploadQueuedItems } from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";
import { DocumentUtil } from "models/Document.model";

const SUPPORTED_FILE_EXTS = [FILE_TYPES.PDF];

type Props = {
  name: string;
  fileName: string;
  fileType?: string;
  fileSize: number;
  fileModifiedDate?: string;
  uploadStatus: DocumentStatus;
  errorCode?: DocumentErrorCode | null;
  isDraggable?: boolean;
  onClickCard?: () => void;
  isSelected?: boolean;
  linkMessage?: string;
  actionButton?: React.ReactNode;
};

const DocumentCard: React.FC<Props> = ({
  name,
  fileName,
  fileSize,
  fileModifiedDate = "",
  isDraggable = false,
  isSelected = false,
  onClickCard,
  errorCode,
  linkMessage = "",
  actionButton,
  uploadStatus: docUploadStatus,
  ...otherProps
}) => {
  const { t } = useTranslation();

  let { fileNameWithoutExt, fileExt } = React.useMemo(() => getFileNameComponents(fileName), [fileName]);
  const queuedItems = useStoreSelector(selectApplicationDocumentUploadQueuedItems);

  if (!SUPPORTED_FILE_EXTS.includes(fileExt.toLowerCase())) {
    fileNameWithoutExt = fileNameWithoutExt.concat(fileExt);
    fileExt = "";
  }

  const uploadStatus = React.useMemo(() => {
    if (DocumentUtil.isPendingNotInQueue({ name, uploadStatus: docUploadStatus }, queuedItems)) {
      return DocumentStatus.Error;
    }

    return docUploadStatus;
  }, [queuedItems, docUploadStatus, name]);

  const isProcessing = React.useMemo(() => ![DocumentStatus.Completed, DocumentStatus.Error].includes(uploadStatus), [
    uploadStatus,
  ]);

  const errorMessage = React.useMemo(() => {
    return t(DocumentErrorCodeUtil.getErrorMessage(errorCode));
  }, [t, errorCode]);

  return (
    <StyledCard
      data-testid={`DocumentCard-${fileName}`}
      data-uploadstatus={uploadStatus}
      $isDraggable={isDraggable}
      selected={isSelected}
      onClick={onClickCard}
      {...otherProps}
    >
      <Guard condition={isDraggable}>
        <StyledDragHandle>
          <StyledDragHandleIcon name={ODL_ICONS.DRAG_VERTICAL} />
        </StyledDragHandle>
      </Guard>

      {/* Information part */}
      <FlexBox flexGrow={1} flexShrink={1} alignItems={"center"} useSpacer={false}>
        <StyledIconContainer>
          <DocumentIcon size={44} status={uploadStatus} />
        </StyledIconContainer>
        <StyledDocumentInfoContainer $isProcessing={isProcessing}>
          <div>
            <strong>{fileNameWithoutExt}</strong>
            <Guard condition={!!fileExt}>
              <span>.{fileExt}</span>
            </Guard>
            <Spacer x={2} component={"span"} display={"inline-block"} />
            <Guard condition={fileSize > 0}>
              <span data-testid={`DocumentCardFileSize-${fileName}`}>({prettyBytes(fileSize || 0)})</span>
            </Guard>
          </div>
          <StyledUploadInfo $isError={uploadStatus === DocumentStatus.Error}>
            <Guard condition={uploadStatus === DocumentStatus.Error}>{errorMessage}</Guard>
            <Guard condition={uploadStatus === DocumentStatus.Pending}>{t(`Queued`)}</Guard>
            <Guard condition={uploadStatus === DocumentStatus.Uploading}>{t(`Uploading...`)}</Guard>
            <Guard condition={uploadStatus === DocumentStatus.Uploaded}>{t(`Scanning for viruses...`)}</Guard>
            <Guard condition={uploadStatus === DocumentStatus.Completed && fileModifiedDate}>
              <Guard condition={linkMessage}>
                <HoverTooltip title={linkMessage}>
                  <FontIcon name={"link-variant"} />
                </HoverTooltip>
              </Guard>
              {formatDateStr(fileModifiedDate, "dd MMM yyyy")}
            </Guard>
          </StyledUploadInfo>
        </StyledDocumentInfoContainer>
      </FlexBox>

      {/* Action part */}
      <Guard condition={actionButton}>
        <FlexBox alignItems={"center"} useSpacer={false}>
          {actionButton}
        </FlexBox>
      </Guard>
    </StyledCard>
  );
};

const StyledCard = styled.div<{ $isDraggable?: boolean; $hasBottomBorder?: boolean; selected: boolean }>(
  ({ theme, $isDraggable, $hasBottomBorder, selected }) => css`
    position: relative;
    padding: 12px;
    display: flex;
    justify-content: space-between;
    background: ${theme.palette.objective.light.white};

    &:hover {
      background: ${theme.palette.objective.blue.light};
    }

    &:active {
      background: ${theme.palette.objective.blue.light};

      &:before {
        content: "";
        position: absolute;
        left: 2px;
        top: 12px;
        bottom: 12px;
        border-left: 4px solid ${theme.palette.primary.main};
        border-radius: 4px;
      }
    }

    ${selected &&
    css`
      background: ${theme.palette.objective.blue.light};

      &:before {
        content: "";
        position: absolute;
        left: 2px;
        top: 12px;
        bottom: 12px;
        border-left: 4px solid ${theme.palette.primary.main};
        border-radius: 4px;
      }
    `}

    ${$isDraggable &&
    css`
      padding-left: 34px;
    `}
  `
);

const StyledIconContainer = styled.div(
  ({ theme }) => css`
    flex-shrink: 0;
    flex-grow: 0;
    flex-basis: auto;
    min-width: 44px;
    min-height: 44px;
  `
);

const StyledDocumentInfoContainer = styled.div<{ $isProcessing: boolean }>(
  ({ theme, $isProcessing }) => css`
    flex-direction: column;
    margin-left: 20px;
    font-size: 12px;
    color: ${theme.palette.text.secondary};
    line-height: 14px;

    & strong {
      font-size: 14px;
      font-weight: 500;
      color: ${theme.palette.text.primary};
    }

    ${$isProcessing &&
    css`
      opacity: 60%;
    `}
  `
);

const StyledDragHandle = styled.div(
  ({ theme }) => css`
    position: absolute;
    left: 8px;
    top: 0;
    bottom: 0;
    align-items: center;
    display: flex;
  `
);

const StyledDragHandleIcon = styled(FontIcon)(
  ({ theme }) => css`
    font-size: 18px;
    color: ${theme.palette.objective.dark.night};
  `
);

const StyledUploadInfo = styled.div<{ $isError: boolean }>(
  ({ theme, $isError }) => css`
    padding-top: 8px;
    color: ${$isError ? theme.palette.error.main : theme.palette.text.secondary};
  `
);

export default DocumentCard;
