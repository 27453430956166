import { DocumentStatus } from "models/DocumentStatus.model";
import { DocumentErrorCode } from "models/DocumentErrorCode.model";
import type { IQueuedItem } from "store/app-state/application-document-upload-queue/applicationDocumentUploadQueue";

export type IDocumentEntity = {
  name: string;
  fileName: string;
  fileType: string;
  fileSize: number;
  fileVersion: number;
  fileModifiedDate: string;
  referenceUrl: string;
  presignedUrl: string;
  uploadStatus: DocumentStatus;
  errorCode: DocumentErrorCode | null;
  createdDate: string;
  isMergedFile?: boolean;
};

export const DocumentUtil = {
  isSettled: (document: IDocumentEntity) => {
    return document.uploadStatus === DocumentStatus.Completed || document.uploadStatus === DocumentStatus.Error;
  },
  /**
   * Check if a document status is pending and not in queue, mark as FAILED
   * @param document
   * @param queuedItems
   * @returns
   */
  isPendingNotInQueue: (
    document: Pick<IDocumentEntity, "uploadStatus" | "name"> | undefined,
    queuedItems: IQueuedItem[]
  ) => {
    // Check pending upload document not in the queue
    return (
      document &&
      document.uploadStatus === DocumentStatus.Pending &&
      !queuedItems.some((queuedDoc) => queuedDoc.documentName === document.name)
    );
  },
  isUnsettled: (document: IDocumentEntity, queuedItems: IQueuedItem[]) => {
    return !DocumentUtil.isSettled(document) && !DocumentUtil.isPendingNotInQueue(document, queuedItems);
  },
};
